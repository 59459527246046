import Vue from 'vue'
import VueMeta from 'vue-meta'
import Icon from 'vue-awesome/components/Icon'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export function createApp() {
  Vue.config.productionTip = false
  Vue.component('RecycleScroller', RecycleScroller)
  Vue.component('v-icon', Icon)
  Vue.use(VueMeta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
  })
  const router = createRouter()
  const store = createStore()

  // sync so that route state is available as part of the store
  sync(store, router)

  Sentry.init({
    dsn: 'https://c8e6b413b41049a0b7b018cc784e9d8e@sentry.io/2998586',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
  // create the app instance, injecting both the router and the store
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  // expose the app, the router and the store.
  return { app, router, store }
}
