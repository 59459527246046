<template>
  <div class="social-media-bar">
    <a
      href="https://facebook.com/art42net"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Follow us on Facebook"
    >
      <v-icon name="brands/facebook-square" />
    </a>
    <a
      href="https://twitter.com/art42net"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Follow us on Twitter"
    >
      <v-icon name="brands/twitter-square" />
    </a>
    <a
      href="https://instagram.com/art42net"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Follow us on Instagram"
    >
      <v-icon name="brands/instagram" />
    </a>
    <a
      href="https://pinterest.com/art42net"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Follow us on Pinterest"
    >
      <v-icon name="brands/pinterest-square" />
    </a>
  </div>
</template>

<script>
import 'vue-awesome/icons/brands/facebook-square'
import 'vue-awesome/icons/brands/instagram'
import 'vue-awesome/icons/brands/pinterest-square'
import 'vue-awesome/icons/brands/twitter-square'

export default {
  name: 'SocialMediaBar'
}
</script>

<style scoped>
.social-media-bar a {
  font-size: 28px;
  padding: 18px;
  display: inline-block;
}
</style>
