<template>
  <div
    id="app"
    :style="{
      'margin-top': navigationVisibility ? '60px' : '0px'
    }"
  >
    <Navigation />
    <router-view />
  </div>
</template>
<script>
// icons
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'app',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Art42',
    // all titles will be injected into this template
    titleTemplate: '%s | Enjoy an infinite stream of AI generated Art',
    htmlAttrs: {
      lang: 'en'
    },
    link: [
      {
        rel: 'preconnect',
        href: 'https://cdn.vv42.net/',
        crossorigin: true
      },
      {
        rel: 'preconnect',
        href: 'https://fonts.gstatic.com/',
        crossorigin: true
      },
      { rel: 'favicon', href: 'favicon.ico' }
    ],
    script: [
      {
        src:
          'https://d3aln0nj58oevo.cloudfront.net/button/script/158196272283042370.js',
        async: true,
        defer: true
      },
      {
        src: 'https://www.googletagmanager.com/gtag/js?id=UA-783279-21',
        async: true
      }
    ],
    meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no!'
      },
      {
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      },
      {
        name: 'msapplication-TileColor',
        content: '#ffe66d'
      },
      {
        name: 'theme-color',
        content: '#ffffff'
      },
      {
        name: 'apple-mobile-web-app-status-bar-style',
        content: 'black-translucent'
      },
      {
        name: 'description',
        content:
          'art42.net showcases an infinite stream of unique AI art. Bookmark and collect the works you like!'
      },
      { property: 'og:site_name', content: 'Art42.net' },
      {
        property: 'og:title',
        content: '#aiart: Enjoy an infinite stream of AI generated Art'
      },
      { property: 'og:url', content: 'https://art42.net' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:description',
        content:
          'art42.net showcases an infinite stream of unique AI art. Bookmark and collect the works you like!'
      },
      {
        property: 'og:image',
        content:
          'https://cdn.vv42.net/file/art42-cdn/cubism/seed_0000353905.jpg'
      },
      { property: 'og:image:width', content: '1024' },
      { property: 'og:image:height', content: '1024' },

      {
        itemprop: 'name',
        content: '#aiart: Enjoy an infinite stream of AI generated Art'
      },
      { itemprop: 'url', content: 'https://art42.net' },
      {
        itemprop: 'thumbnailUrl',
        content:
          'https://cdn.vv42.net/file/art42-cdn/cubism/seed_0000353905.jpg'
      },
      {
        itemprop: 'image',
        content:
          'https://cdn.vv42.net/file/art42-cdn/cubism/seed_0000353905.jpg'
      },
      {
        itemprop: 'description',
        content:
          'art42.net showcases an infinite stream of unique AI art. Bookmark and collect the works you like!'
      },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: 'https://art42.net' },
      { name: 'twitter:creator', content: '@art42net' },
      {
        name: 'twitter:title',
        content: '#aiart: Enjoy an infinite stream of AI generated Art'
      },
      {
        name: 'twitter:description',
        content:
          'art42.net showcases an infinite stream of unique AI art. Bookmark and collect the works you like!'
      }
    ]
  },
  components: {
    Navigation
  },
  beforeMount() {
    // this.$store.dispatch("initApp");
    this.$router.beforeEach((to, from, next) => {
      this.$store.dispatch('setShowMenu', false).then(() => next())
      this.$store.dispatch('gtagPageView', {
        page_title: to.name,
        page_path: to.path,
        page_location: window.location.href
      })
    })
  },
  computed: {
    navigationVisibility() {
      return this.$store.state.navigationVisibility
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Philosopher&display=swap');
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Philosopher', sans-serif;
  font-display: swap;
  color: #374350;
  background-color: #fff;
}
a {
  color: #2c3e50;
}

.fa-icon {
  width: auto;
  height: 0.8em; /* or any other relative font sizes */

  /* You would have to include the following two lines to make this work in Safari */
  max-width: 100%;
  max-height: 100%;
}
</style>
