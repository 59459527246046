import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Vue from 'vue'

Vue.use(VueRouter)

export const createRouter = () => {
  const routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/artwork/:artist/:id',
      name: 'artwork',
      component: () =>
        import(/* webpackChunkName: "artwork" */ '../views/Artwork.vue')
    },
    {
      path: '/favorites',
      name: 'favorites',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "favorites" */ '../views/Favorites.vue')
    },
    {
      path: '/tv',
      name: 'tv',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "favorites" */ '../views/Tv.vue')
    },
    { path: '*', redirect: '/' }
  ]

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}
