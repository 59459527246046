import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { rand, getRandomItem, gTagId, uuidv4 } from '../util.js'
Vue.use(Vuex)

const plugins = []

if (typeof window !== 'undefined') {
  plugins.push(
    createPersistedState({
      overwrite: true,
      paths: ['uuid', 'favorites']
    })
  )
}
export function createStore() {
  return new Vuex.Store({
    state: {
      uuid: null,
      currentArtist: 'cubism',
      maxImages: 450000,
      prerenderImages: 10,
      artStream: [],
      favorites: {},
      lockedAdd: false,
      showMenu: false,
      navigationVisibility: true,
      dataLayer: []
    },
    mutations: {
      UPDATE_ART_STREAM(state, payload) {
        state.artStream = state.artStream.concat(payload)
      },
      SET_UUID(state, payload) {
        state.uuid = payload
      },
      SET_LOCKED_ADD(state, payload) {
        state.lockedAdd = payload
      },
      ADD_FAVORITES(state, payload) {
        Vue.set(state.favorites, payload.id, payload)
      },
      REMOVE_FAVORITES(state, payload) {
        Vue.delete(state.favorites, payload.id)
      },
      SET_SHOW_MENU(state, payload) {
        state.showMenu = payload
      },
      SET_NAVIGATION_VISIBILITY(state, payload) {
        state.navigationVisibility = payload
      },
      GTAG_PUSH(state, payload) {
        state.dataLayer.push(payload)
        // we need this becaus of the arguments format that google expects
        if (typeof window !== 'undefined') {
          window.gtag.apply(null, payload)
        }
      }
    },
    actions: {
      addArtItems({ commit, state, dispatch }, countItems) {
        const newArtStream = [...Array(countItems)].map(() =>
          getRandomItem(state.maxImages, state.currentArtist)
        )
        commit('UPDATE_ART_STREAM', newArtStream)
        dispatch('gtagEvent', {
          action: 'scrollInteraction',
          event: {
            event_category: 'navigation',
            event_label: 'infiniteScroll',
            value: 1
          }
        })
      },
      setShowMenu({ commit, dispatch }, payload) {
        commit('SET_SHOW_MENU', payload)
        dispatch('gtagEvent', {
          action: 'menuInteraction',
          event: {
            event_category: 'navigation',
            event_label: 'main_nav',
            value: 1
          }
        })
      },
      setNavigationVisibility({ commit, dispatch }, payload) {
        commit('SET_NAVIGATION_VISIBILITY', payload)
      },
      addFavorite({ commit, dispatch }, payload) {
        commit('ADD_FAVORITES', payload)
        dispatch('gtagEvent', {
          action: 'addFavorite',
          event: {
            event_category: 'conversion',
            event_label: payload.id,
            value: 1
          }
        })
      },
      removeFavorite({ commit, dispatch }, payload) {
        commit('REMOVE_FAVORITES', payload)
        dispatch('gtagEvent', {
          action: 'removeFavorite',
          event: {
            event_category: 'conversion',
            event_label: payload.id,
            value: 1
          }
        })
      },
      setLockedAdd({ state, commit }, payload) {
        if (state.lockedAdd === payload) return
        commit('SET_LOCKED_ADD', payload)
      },
      gtagEvent({ state, commit }, { action, event }) {
        commit('GTAG_PUSH', ['event', action, event])
      },
      gtagPageView({ state, commit }, payload) {
        commit('GTAG_PUSH', ['config', gTagId, payload])
      },
      initApp({ dispatch, commit, state }) {
        // Googgle Tag
        if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || []
          window.gtag = function () {
            window.dataLayer.push(arguments)
          }
          commit('GTAG_PUSH', ['js', new Date()])
          commit('GTAG_PUSH', ['config', gTagId])
        }
        if (!state.artStream.length) {
          dispatch('addArtItems', state.prerenderImages)
        }
        if (!state.uuid) {
          commit('SET_UUID', uuidv4())
        }
      }
    },
    plugins
  })
}
