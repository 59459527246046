import { render, staticRenderFns } from "./SocialMediaBar.vue?vue&type=template&id=658306ff&scoped=true&"
import script from "./SocialMediaBar.vue?vue&type=script&lang=js&"
export * from "./SocialMediaBar.vue?vue&type=script&lang=js&"
import style0 from "./SocialMediaBar.vue?vue&type=style&index=0&id=658306ff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658306ff",
  null
  
)

export default component.exports