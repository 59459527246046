const baseImgCdn = 'https://cdn.vv42.net/file/art42-cdn'
const gTagId = 'UA-783279-21'
function rand(min, max) {
  return min + ~~(Math.random() * (max - min))
}
function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}
function getUrl(artistId, seed) {
  return `${baseImgCdn}/${artistId}/seed_${seed}.jpg`
}

function getRandomItem(maxImages, artistId) {
  const id = pad(rand(0, maxImages), 10)
  return {
    id,
    url: getUrl(artistId, id)
  }
}
//https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export { gTagId, baseImgCdn, rand, pad, getRandomItem, getUrl, uuidv4 }
