<template>
  <div class="art-item" :key="config.id">
    <div class="img-wrapper">
      <img
        @click="imageClicked"
        :alt="`Unique Art piece image generated by an AI. Art ID: ${config.id}`"
        :src="config.url"
        width="1024"
        height="1024"
      />
      <div class="actions" v-if="!hideActions">
        <button
          v-if="!isFavorite"
          class="favorite"
          :aria-label="`Add Art ID: ${config.id} to your favorites`"
          @click="addFavorite"
        >
          <v-icon name="regular/heart" />
        </button>
        <button
          v-if="isFavorite"
          class="favorite active"
          :aria-label="`Remove Art ID: ${config.id} from your favorites`"
          @click="removeFavorite"
        >
          <v-icon name="heart" />
        </button>
        <br />
        <a
          class="peecho-print-button"
          v-if="!hidePrint"
          data-filetype="jpeg"
          data-pages="1"
          data-width="1024"
          data-height="1024"
          :data-src="config.url"
          :data-thumbnail="config.url"
          @click="printClicked()"
          ref="peecho"
          >order print</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-awesome/icons/heart'
import 'vue-awesome/icons/regular/heart'
export default {
  name: 'Card',
  props: {
    config: Object,
    favorites: Object,
    isFavorite: Boolean,
    hidePrint: Boolean,
    hideActions: Boolean
  },
  methods: {
    addFavorite() {
      this.$emit('addFavorite', this.config)
    },
    imageClicked() {
      this.$emit('imageClicked', this.config)
    },
    removeFavorite() {
      this.$emit('removeFavorite', this.config)
    },
    printClicked() {
      const $peecho = this.$refs['peecho']
      window.peecho.send($peecho)
      this.$store.dispatch('gtagEvent', {
        action: 'buyIntent',
        event: {
          event_category: 'conversion',
          event_label: 'cardLocation',
          value: 1
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.art-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.img-wrapper {
  width: 100%;
  position: relative;
  /* padding-bottom: 100%; */
  max-width: 320px;
  max-height: 320px;
  background-color: whitesmoke;
}
.actions {
  position: absolute;
  bottom: -90px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
button.favorite {
  border: none;
  background-color: transparent;
  font-size: 28px;
  outline: none;
}
button.favorite svg {
  fill: lightgray;
}
button.favorite.active svg {
  fill: #5d5d5d;
}
.peecho-print-button {
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (min-height: 512px) and (min-width: 512px) {
  .img-wrapper {
    max-width: 512px;
    max-height: 512px;
  }
  img {
    max-width: 512px;
    max-height: 512px;
  }
}
@media screen and (min-height: 1080px) and (min-width: 1080px) {
  .img-wrapper {
    max-width: 750px;
    max-height: 750px;
  }
  img {
    max-width: 750px;
    max-height: 750px;
  }
}
@media screen and (min-height: 2160px) and (min-width: 2160px) {
  .img-wrapper {
    max-width: 1024px;
    max-height: 1024px;
  }
  img {
    max-width: 1024px;
    max-height: 1024px;
  }
}
img {
  display: block;
  width: 100%;
  max-width: 1024px; /*actual image width*/
  height: auto; /* maintain aspect ratio*/
  margin: auto; /*optional centering of image*/
}
</style>
