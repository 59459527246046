import { createApp } from './main'

const { app, router, store } = createApp()

if (window.__INITIAL_STATE__) {
  // We initialize the store state with the data injected from the server
  // store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(async () => {
  await store.dispatch('initApp')
  app.$mount('#app')
})
