<template>
  <div id="nav" v-if="navigationVisibility">
    <dropdown-menu v-model="showMenu" :right="true">
      <div class="dropdown-toggle">
        <v-icon name="bars" />
      </div>
      <ul class="nav-wrapper" slot="dropdown">
        <li>
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'favorites' }">
            Favorites
            <v-icon name="heart" />
            ({{ favoritesCount }})
          </router-link>
        </li>
        <li class="social-media">
          <SocialMediaBar />
        </li>
      </ul>
    </dropdown-menu>
  </div>
</template>

<script>
import 'vue-awesome/icons/heart'
import 'vue-awesome/icons/regular/heart'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/brands/facebook-square'
import 'vue-awesome/icons/brands/instagram'
import 'vue-awesome/icons/brands/pinterest-square'
import 'vue-awesome/icons/brands/twitter-square'

import DropdownMenu from '@/components/DropDownMenue.vue'
import SocialMediaBar from '@/components/SocialMediaBar.vue'
export default {
  name: 'Navigation',
  components: {
    DropdownMenu,
    SocialMediaBar
  },
  computed: {
    showMenu: {
      get: function () {
        return this.$store.state.showMenu
      },
      set: function (newValue) {
        this.$store.dispatch('setShowMenu', newValue)
      }
    },
    route() {
      return this.$store.state.route
    },
    navigationVisibility() {
      return this.$store.state.navigationVisibility
    },
    favoritesCount() {
      return Object.keys(this.$store.state.favorites).length
    }
  }
}
</script>

<style scoped>
#nav {
  top: 0px;
  width: 100%;
  text-align: center;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
}

.nav-wrapper {
  list-style: none;
  margin: 0;
  padding: 32px 32px 0 32px;
  border-bottom: 4px solid rgba(0, 0, 0, 0.8);
  background-color: #fff;
}
.nav-wrapper a {
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 22px;
}

.dropdown-toggle {
  padding: 8px;
  font-size: 24px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
}
.social-media {
  border-top: 1px solid #efefef;
  margin-top: 32px;
}
</style>
