<template>
  <div class="home" v-if="artStream.length">
    <div class="intro">
      <h2>&#8734; stream of AI generated art.</h2>
      <p>
        Explore the infinite creativity of this AI artist that was trained on a
        carefully selected set of cubist art pieces.
      </p>
      <p>
        Refresing the page will generate new paintings. Make sure you
        <v-icon name="heart" />the ones you want to keep.
      </p>
      <SocialMediaBar />
    </div>
    <div
      class="content"
      :style="{
        'margin-top': -canvasSize / 4 + 'px'
      }"
    >
      <RecycleScroller
        class="scroller"
        :items="artStream"
        :prerender="prerender"
        :item-size="canvasSize"
        :pageMode="true"
        :emitUpdate="true"
        :buffer="3 * canvasSize"
        key-field="id"
        v-slot="{ item }"
        @update="onUpdate"
      >
        <div
          class="wrapper"
          :style="{
            height: canvasSize + 'px'
          }"
        >
          <Card
            :config="item"
            :isFavorite="isFavorite(item.id)"
            :hidePrint="true"
            @imageClicked="imageClicked"
            @addFavorite="addFavorite"
            @removeFavorite="removeFavorite"
          />
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import innerHeight from 'ios-inner-height'
import Vue from 'vue'
import Card from '@/components/Card.vue'
import SocialMediaBar from '@/components/SocialMediaBar.vue'
export default {
  name: 'home',
  components: {
    Card,
    SocialMediaBar
  },
  data() {
    return {
      canvasSize: 320,
      itemSize: 320
    }
  },
  computed: {
    artStream() {
      return this.$store.state.artStream
    },
    favorites() {
      return this.$store.state.favorites
    },
    lockedAdd() {
      return this.$store.state.lockedAdd
    },
    prerender() {
      return this.$store.state.prerenderImages
    },
    artStreamLength() {
      return this.artStream.length
    }
  },
  beforeMount() {
    this.calculateLayout()
  },
  methods: {
    isFavorite(id) {
      return !!this.$store.state.favorites[id]
    },
    imageClicked(e) {
      this.$router.push({
        name: 'artwork',
        params: { artist: 'cubism', id: e.id }
      })
    },
    addFavorite(e) {
      this.$store.dispatch('addFavorite', e)
      fetch('/favorite', {
        method: 'post',
        body: JSON.stringify(e)
      })
    },
    removeFavorite(e) {
      this.$store.dispatch('removeFavorite', e)
    },
    onUpdate(startIndex, endIndex) {
      if (endIndex === this.artStreamLength && !this.lockedAdd) {
        this.$store.dispatch('setLockedAdd', true)
        this.$store.dispatch('addArtItems', 1 * this.prerender)
      } else {
        this.$store.dispatch('setLockedAdd', false)
      }
    },
    calculateLayout() {
      this.canvasSize = innerHeight()
      this.itemSize = this.canvasSize <= 1024 ? this.canvasSize : 1024
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ececec;
}
.intro {
  position: relative;
  background-color: #fff;
  padding: 25px 10px 15px 10px;
  text-align: center;
  z-index: 2;
}
.content {
  position: relative;
  z-index: 1;
}
</style>
